import cache from '../util/cache';
import jwt_decode from 'jwt-decode';

const TOKEN = 'FC_TOKEN';

const getToken = () => {
  return cache.getItem({ key: TOKEN });
};

function getExpiryDateFromToken(token) {
  const decodedToken = token && jwt_decode(token);
  return decodedToken && parseInt(decodedToken.exp);
}

const setToken = value => {
  return cache.setItem({ key: TOKEN, value });
};

const removeToken = () => {
  return cache.removeItem({ key: TOKEN });
};

export default {
  getToken,
  setToken,
  removeToken,
  getExpiryDateFromToken
};
