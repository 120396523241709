import {
  getStoreData,
  checkout,
  getOrderById,
  makePayment,
  confirmPayment
} from '../service/microsite.service';
import { sum, flatten } from 'lodash';

const state = {
  storeData: null,
  orderId: null,
  selectedProductId: null,
  isLoadingStoreData: false,
  paymentInprogress: false,
  paymentResponse: '',
  checkOutProductCount: 0,
  paymentCompleted: false,
  deliveryOption: 'pickUp',
  orders: [],
  subTotal: 0
};

const getters = {
  storeData: state => state.storeData,
  isLoadingStoreData: state => state.isLoadingStoreData,
  checkOutProductCount: state => {
    if (!state.orders) {
      return 0;
    }
    return state.orders.length;
  },
  subTotal: state => {
    if (!state.orders) {
      return 0;
    }
    let deliveryCost = state.storeData?.deliveryCost || 0;
    let _additionalCost =
      state.deliveryOption === 'delivery' ? deliveryCost : 0;

    let _paperBagCost =
      state.orders.length > 0 ? state.storeData?.paperBagCost || 0 : 0;

    return (
      _additionalCost +
      _paperBagCost +
      sum(
        flatten(
          state.orders.map(order =>
            order.orderDetails.map(item => item.totalPrice)
          )
        )
      )
    );
  },
  selectedProductId: state => state.selectedProductId,
  orders: state => state.orders,
  orderId: state => state.orderId,
  paperBagCost: state => state.storeData?.paperBagCost || 0,
  paymentInprogress: state => state.paymentInprogress,
  paymentCompleted: state => state.paymentCompleted,
  paymentResponse: state => state.paymentResponse
};

const actions = {
  async getStore({ commit }, payload) {
    try {
      commit('mutateField', {
        field: 'isLoadingStoreData',
        value: true
      });

      const { data, error } = await getStoreData({ ...payload });
      if (error) {
        throw error;
      }
      commit('mutateField', { field: 'storeData', value: data });
    } catch ({ error }) {
      throw Error('Error loading store.');
    } finally {
      commit('mutateField', {
        field: 'isLoadingStoreData',
        value: false
      });
    }
  },
  async addProductToCart({ commit }, payload) {
    let _orders = state.orders || [];
    _orders.push({ ...payload });
    commit('mutateField', { field: 'orders', value: _orders });
  },
  async deleteProductFromCart({ commit }, payload) {
    let _orders = state.orders;
    let order = state.orders.find(order => order.ts === payload.order.ts);
    // if one item in the order delete everything
    if (order.orderDetails.length === 1) {
      _orders = state.orders.filter(order => order.ts !== payload.order.ts);
    } else {
      // if more than one delete just the item removed
      const details = order.orderDetails.filter(
        item => item.name !== payload.detail.name
      );
      let orderIndex = _orders.findIndex(item => item.ts === payload.order.ts);
      _orders[orderIndex].details = details;
    }
    commit('mutateField', { field: 'orders', value: _orders });
  },
  async checkout({ commit }, payload) {
    try {
      let _orders = state.orders || [];
      const { data, error } = await checkout({
        slug: payload.slug,
        payload: { orders: _orders, orderId: state.orderId || '' }
      });
      if (error) {
        throw error;
      }
      commit('mutateField', {
        field: 'orderId',
        value: data.orderId
      });
      if (error) {
        throw error;
      }
    } catch ({ error }) {
      console.log(error);
      throw Error('Error saving orders.');
    }
  },
  async makePayment({ commit }, payload) {
    try {
      commit('mutateField', {
        field: 'paymentInprogress',
        value: true
      });
      const { data, error } = await makePayment({
        storeId: payload.storeId,
        orderId: payload.orderId,
        payload: payload.payment
      });
      if (error) {
        commit('mutateField', {
          field: 'paymentResponse',
          value: {
            status: 'Error',
            data: error
          }
        });
        commit('mutateField', {
          field: 'paymentInprogress',
          value: false
        });
        throw error;
      }
      commit('mutateField', {
        field: 'paymentResponse',
        value: {
          status: 'Success',
          data
        }
      });
    } finally {
      commit('mutateField', {
        field: 'paymentInprogress',
        value: false
      });
    }
  },
  async confirmPayment({ commit }, payload) {
    commit('mutateField', {
      field: 'paymentInprogress',
      value: true
    });
    commit('mutateField', {
      field: 'paymentResponse',
      value: {}
    });

    const { data, error } = await confirmPayment({
      payload: payload.payment
    });

    if (error) {
      commit('mutateField', {
        field: 'paymentResponse',
        value: { status: 'Error', data: error }
      });
      commit('mutateField', {
        field: 'paymentInprogress',
        value: false
      });
      throw new Error(error);
    }

    commit('mutateField', {
      field: 'paymentResponse',
      value: {
        status: 'Success',
        data
      }
    });
  },
  async getOrderById({ commit }, payload) {
    try {
      const { data, error } = await getOrderById({
        slug: payload.slug,
        orderId: payload.orderId
      });
      if (error) {
        throw error;
      }
      commit('mutateField', {
        field: 'orders',
        value: data
      });
      commit('mutateField', {
        field: 'orderId',
        value: payload.orderId
      });
    } catch ({ error }) {
      console.log(error);
      throw Error('Error loading order.');
    }
  },
  async checkPayment({ commit }, payload) {
    const { error, data } = await getOrderById({
      slug: payload.storeId,
      orderId: state.orderId
    });

    if (error) {
      commit('mutateField', {
        field: 'paymentResponse',
        value: { status: 'Error', data: error }
      });
      throw new Error(error);
    }

    if (data && data.length && data[0].status === 'Completed') {
      commit('mutateField', {
        field: 'paymentCompleted',
        value: true
      });
      commit('mutateField', {
        field: 'paymentInprogress',
        value: false
      });
      commit('mutateField', {
        field: 'orderId',
        value: ''
      });
      commit('mutateField', {
        field: 'orders',
        value: []
      });
      commit('mutateField', {
        field: 'paymentResponse',
        value: {}
      });
    }
  }
};

const mutations = {
  mutateField(state, payload) {
    state[payload.field] = payload.value;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
