const _cache = {};

const getItem = ({ key }) => {
  return _cache[key] || '';
};

const setItem = async ({ key, value, expireDate }) => {
  _cache[key] = value;
  if (expireDate) {
    const _timeRemaining = expireDate - Date.now();
    setTimeout(() => {
      delete _cache[key];
    }, _timeRemaining);
  }
};

const removeItem = ({ key }) => {
  _cache[key] = '';
};

export default {
  getItem,
  setItem,
  removeItem
};
