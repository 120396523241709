import Vue from 'vue';
import App from './App.vue';

// import Flutterwave from 'vue-flutterwave';

import vuetify from './plugins/vuetify';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import store from './store/store';

import router from './router/index';
import Vuebar from 'vuebar';
import VueGtag from 'vue-gtag';

import './plugins/base';

Vue.config.productionTip = false;

Vue.use(Vuebar);
Vue.use(VueToast, {
  // One of the options
  position: 'top'
});

// Vue.use(Flutterwave, {
//   publicKey: 'FLWPUBK_TEST-4550165677fdcf951548729e3ff6b950-X'
// });

Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-QSGCBT2XLC'
    }
  },
  router
);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');

console.log(process.env.VUE_APP_API_BASE_URL, 'VUE_APP_API_BASE_URL');
