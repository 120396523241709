import { get, post } from '../util/api';

export const getOrderById = async ({ slug, orderId, params = {} }) => {
  return await get(`/store/${slug}/${orderId}`, params, false);
};

export const getStoreData = async ({ slug }) => {
  return await get(`/store/${slug}`, {}, false);
};

export const makePayment = async ({ storeId, orderId, payload }) => {
  return await post(`/store/${storeId}/${orderId}`, payload, false);
};

export const checkout = async ({ slug, payload }) => {
  return await post(`/store/checkout/c/${slug}`, payload, false);
};

export const confirmPayment = async ({ payload }) => {
  return await post(`/store/validate-payment`, payload, false);
};

export const verifyPayment = async ({ storeId, orderId, transactionId }) => {
  return await get(
    `/store/verify/v/verify-payment?orderId=${orderId}&storeId=${storeId}&transactionId=${transactionId}`,
    {},
    false
  );
};
