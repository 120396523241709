import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => {
    if (error.name !== 'NavigationDuplicated') {
      // throw error;
      // console.log(error);
    }
  });
};

const router = new Router({
  mode: 'history',
  routes
});

router.beforeRouteUpdate = (to, from, next) => {
  next();
};

router.beforeResolve((to, from, next) => {
  next();
});
router.beforeEach((to, from, next) => {
  next();
});

router.afterEach(() => {});

export default router;
