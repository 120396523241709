import Vue from 'vue';
import Vuex from 'vuex';

import sidebar from './sidebar';
import microsite from './microsite';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: { sidebar, microsite }
});
