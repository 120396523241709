import axios from 'axios';
import tokenUtil from '../util/token';
import { routeToLogin } from '../util/route';

const _GET = 'GET';
const _POST = 'POST';
const _PUT = 'PUT';
const _DELETE = 'DELETE';

const passParams = [_GET, _DELETE];
const wrapParams = [_POST, _PUT];
const baseURL = process.env.VUE_APP_API_BASE_URL;

function getErrorMessage(error) {
  if (error && error.response && error.response.data) {
    return error.response.data;
  }
  return error;
}
const request = async ({
  method,
  apiPath,
  params = {},
  options = {},
  responseType = {},
  includeToken
}) => {
  axios.defaults.withCredentials = true;

  const _apiPath = `${baseURL}${apiPath}`;

  return axios({
    method,
    url: _apiPath,
    headers: {
      'Content-Type': options['Content-Type'] || 'application/json',
      ...(includeToken && { Authorization: `Bearer ${tokenUtil.getToken()}` })
    },
    ...(passParams.includes(method) && { params }),
    ...(wrapParams.includes(method) && { data: params }),
    ...{ ...responseType } // response type for document
  })
    .then(res => res.data)
    .catch(error => {
      if (error.response.status === 401) {
        tokenUtil.removeToken();
        routeToLogin();
      } else if (error.response.status === 404) {
        return { error: 'Document Not Found' };
      } else {
        return getErrorMessage(error);
      }
    });
};

export const get = (apiPath, queryParams, includeToken) => {
  return request({ method: _GET, apiPath, params: queryParams, includeToken });
};

export const post = (apiPath, params = {}, opts = {}, includeToken) => {
  return request({
    method: _POST,
    apiPath,
    params,
    options: opts,
    includeToken
  });
};

export const put = (apiPath, params = {}, includeToken) => {
  return request({ method: _PUT, apiPath, params, includeToken });
};

export const del = (apiPath, queryParams, includeToken) => {
  return request({
    method: _DELETE,
    apiPath,
    params: queryParams,
    includeToken
  });
};

export const download = (
  apiPath,
  queryParams,
  options,
  responseType,
  includeToken
) => {
  return request({
    method: _GET,
    apiPath,
    params: queryParams,
    options,
    responseType,
    includeToken
  });
};
