const routes = [
  {
    path: '/',
    redirect: 'home',
    component: () => import('@/layouts/default-layout/Defaultlayout'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/Home.vue')
      }
    ]
  },
  {
    path: '/home',
    component: () => import('@/layouts/default-layout/Defaultlayout'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/Home.vue')
      }
    ]
  },
  {
    path: '/about',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/layouts/page-layout/Pagelayout'),
    children: [
      {
        name: 'about',
        path: '/about',
        meta: {
          title: 'About Us'
        },
        component: () => import('@/views/about/About.vue')
      }
    ]
  },
  {
    path: '/products',
    meta: {
      requiresAuth: false,
      title: 'Products'
    },
    component: () => import('@/layouts/page-layout/Pagelayout'),
    children: [
      {
        meta: {
          title: 'Products'
        },
        name: 'product',
        path: '/product',
        component: () => import('@/views/products/Products.vue')
      }
    ]
  },
  {
    path: '/faq',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/layouts/page-layout/Pagelayout'),
    children: [
      {
        meta: {
          title: 'FAQs'
        },
        name: 'faq',
        path: '/faq',
        component: () => import('@/views/faq/Faq.vue')
      }
    ]
  },
  {
    path: '/location',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/layouts/page-layout/Pagelayout'),
    children: [
      {
        meta: {
          title: 'Location'
        },
        name: 'location',
        path: '/location',
        component: () => import('@/views/location/Location.vue')
      }
    ]
  },
  {
    path: '/blog',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/layouts/page-layout/Pagelayout'),
    children: [
      {
        meta: {
          requiresAuth: false,
          title: 'Blog'
        },
        name: 'blog',
        path: '/blog',
        component: () => import('@/views/blog/Blog.vue')
      }
    ]
  },
  {
    path: '/blogs',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/layouts/blog-layout/Bloglayout'),
    children: [
      {
        meta: {
          requiresAuth: false,
          title: 'Blog'
        },
        name: 'blogs',
        path: '/blogs/steroids',
        component: () => import('@/views/blog/content/steroids.vue')
      },
      {
        meta: {
          requiresAuth: false,
          title: 'Blog'
        },
        name: 'blogs',
        path: '/blogs/steroids-part-two',
        component: () => import('@/views/blog/content/steriods-part-two.vue')
      }
    ]
  },
  {
    path: '/register',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/layouts/page-layout/Pagelayout'),
    children: [
      {
        meta: {
          requiresAuth: false,
          title: 'Sign Up'
        },
        name: 'register',
        path: '/register',
        component: () => import('@/views/register/Register.vue')
      }
    ]
  },
  {
    path: '/store',
    meta: {
      requiresAuth: false
    },
    props: true,
    component: () => import('@/layouts/microsite-layout/Micrositelayout'),
    children: [
      {
        name: 'store',
        path: '/store',
        component: () => import('@/views/microsite/default.vue')
      },
      {
        name: 'store-home',
        path: ':slug',
        component: () => import('@/views/microsite/home.vue')
      },
      {
        name: 'store-product',
        path: ':slug/:productId',
        component: () => import('@/views/microsite/product.vue')
      },
      {
        name: 'my-store-cart',
        path: ':slug/c/my-cart',
        component: () => import('@/views/microsite/cart.vue')
      },
      {
        name: 'my-store-cart-order',
        path: ':slug/c/my-cart/:orderId',
        component: () => import('@/views/microsite/cart.vue')
      },
      {
        name: 'checkout',
        path: ':slug/checkout/:orderId',
        component: () => import('@/views/microsite/checkout.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/views/error/PageNotFound')
  }
];

export default routes;
